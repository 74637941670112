<template>
    <section id="dashboard-fiordo-asociacion-lotes">

        <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config1"
                :dataUrl="data1"
                :editUrl="edit1"
                rowSelection="single"
                @selectionChanged="selectionLotesBrunaTable">
                </ag-grid-table>
            </b-col>
        </b-row>
        <b-row>
            <b-col sm="12">
                <ag-grid-table
                :configUrl="config2"
                :dataUrl="data2"
                :editUrl="edit2"
                rowSelection="single"
                @selectionChanged="selectionLotesQadTable">
                </ag-grid-table>
            </b-col>
        </b-row>
        <b-button :disabled="!formatData.selected" variant="primary" class="asociar-lotes" @click="showModal">Asociar Lote</b-button>
        <b-modal ref="my-modal" hide-footer hide-header>
          <div class="d-block text-center">
            <h3 style="margin-bottom: 30px;">¿Quieres asociar los siguientes lotes?</h3>
          </div>
          <div class="text-center">
            Lote Bruna: {{ this.formatData.selectBrunaTable }} 
            Lote QAD: {{ this.formatData.selectQadTable}}
          </div>
          <b-button class="mt-3" variant="outline-danger" block @click="hideModal">Asociar Lotes</b-button>
          <b-button class="mt-2" variant="outline-warning" block @click="toggleModal">No</b-button>
        </b-modal>
    </section>
</template>
<script>
import AgGridTable from '@/views/brunacomponents/ag-grid-table/AgGridTable.vue'
import useApiServices from '@/services/useApiServices.js';
import
{
  BRow,
  BCol,
  BButton,
} from "bootstrap-vue";
export default {  
    components: {
        AgGridTable,
        BRow,
        BCol,
        BButton,
    },
    data()
    {
        return {
            config1: useApiServices.asociarLotesBrunaConfig,
            data1: useApiServices.asociarLotesBrunaData,
            edit1: useApiServices.asociarLotesBrunaEdit,
            config2: useApiServices.asociarLotesQadConfig,
            data2: useApiServices.asociarLotesQadData,
            edit2: useApiServices.asociarLotesQadEdit,
            
            formatData:{

                selected: false,
                selectBrunaTable: null,
                selectQadTable: null,
                
            },

        };
    },
    methods: { 
        
        async selectionLotesBrunaTable(rows){
        this.formatData.selectBrunaTable = rows[0].meta_data.lote;
          if (this.formatData.selectBrunaTable != null && this.formatData.selectQadTable  != null) {
            this.formatData.selected = true
          };

        },

        async selectionLotesQadTable(rows){
        this.formatData.selectQadTable = rows[0].meta_data.lote
          if (this.formatData.selectBrunaTable != null && this.formatData.selectQadTable  != null) {
            this.formatData.selected = true
          };
        },

        showModal() {
        this.$refs['my-modal'].show()
        },
        hideModal() {
          this.$refs['my-modal'].hide()
        },
        toggleModal() {
          this.$refs['my-modal'].toggle('#toggle-btn')
        }
        
    },
    mounted()
    {
     
    },
    
};
</script>
<style lang="scss" scoped>
span {
  font-size: 14px;
}

.mt-3{
    border: 1px solid green !important;
    background-color: green !important;
    color: white !important;
}

.mt-3:hover{

    transition: 0.3s ease-in !important;   
    border: 1px solid green !important;
    background-color: transparent !important; 
    color: green !important;
    color: white;
}

.mt-2{
    border: 1px solid red !important;
    background-color: red !important;
    color: white !important;
}

.mt-2:hover{

    transition: 0.5s ease-in !important;
    border: 1px solid red !important;
    background-color: transparent !important;
    color: red !important;
}

</style>